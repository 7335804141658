<template>
  <v-dialog v-model="display" max-width="750px">
    <v-card color="primary" class="border-radius-5">
      <v-img
        position="center bottom"
        :src="images.curves"
        :lazy-src="images.curves"
      >
        <v-card-text class="pa-0 fill-height">
          <v-layout row wrap fill-height align-center>
            <v-flex xs12 sm6 pa-4 :text-xs-center="$vuetify.breakpoint.xsOnly">
              <p class="graphik-medium normalcase white--text mb-2 font-22">{{ $ml.get('quoter_buy_with_cash_we_got_your_data') }}</p>
              <p class="graphik-light normalcase white--text font-18 mb-4">{{ $ml.get('quoter_buy_with_cash_we_contact_you') }}</p>

              <v-btn 
                color="pantene"
                depressed dark large
                class="normalcase graphik-bold-italic ma-0 border-radius-5"
                @click="display = false"
              >{{ $ml.get('general_understood') }}</v-btn>
            </v-flex>

            <v-flex xs12 sm6 d-flex align-end :fill-height="$vuetify.breakpoint.smAndUp">
              <v-img
                max-width="350px"
                contain
                position="center bottom"
                :src="images.cuco"
                :lazy-src="images.cuco"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'QuoterDialogBuyWithCash',
  props: {
    show: Boolean
  },
  computed: {
    display: {
      get () {
        return this.show
      },
      set () {
        this.$emit('close')
      }
    },
  },
  data () {
    return {
      images: {
        cuco: require('@/assets/img/images/thank_you.png'),
        curves: require('@/assets/img/images/curves.png')
      }
    }
  }
}
</script>

<style scoped>
</style>
